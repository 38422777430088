
function GetStarted() {
  return (
    <section className="frequentle-asked-area ptb-70" id="FAQ">
      <div className="container">
        <div className="row  ptb-70">
          <div className="col-lg-4 col-md-12">
            <p>Frequently Asked questions 🙋‍♀️</p>
            <h1>Questions? We have the answers you need!</h1>
            <h5>Feel free to reach out to us if you have more questions for us.</h5>
            <div className="product-image">
              {/* <img src={ProductImg} alt="" /> */}
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne" id="one">
                    What is Superline's Digital Lending Platform?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                  <div class="accordion-body">
                    Superline's Digital Lending Platform is an innovative financial solution that provides seamless and efficient lending services through digital channels, catering to diverse financial needs.
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo" id="two">
                    How do I apply for a loan on Superline?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                  <div class="accordion-body">
                    Applying for a loan on Superline is easy! Simply visit our website or download our mobile app, complete the online application, and follow the step-by-step instructions.
                  </div>
                </div>
              </div>
              {/* <br /> */}

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree" id="three">
                    What types of loans does Superline offer?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                  <div class="accordion-body">
                    Superline offers a range of digital lending solutions, including short-term personal loans, tailored to suit various financial requirements.
                  </div>
                </div>
              </div>
              {/* <br /> */}
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour" id="four">
                    What are the eligibility criteria for a loan on Superline?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
                  <div class="accordion-body">
                    Eligibility criteria may vary, but typically include being a salaried professional, meeting minimum income requirements, and providing necessary documentation. Check our eligibility section for specific details.
                  </div>
                </div>
              </div>
              {/* <br /> */}
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive" id="five">
                    How fast can I expect loan approval and disbursement?
                  </button>
                </h2>
                <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
                  <div class="accordion-body">
                    Superline prides itself on quick loan processing. Once you submit your application and necessary documents, approvals can be granted swiftly, and funds disbursed directly to your account.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="connect-us-area">
          <div className="container">
            <div className="top-line">

              <hr />
            </div>
            <div className="row ptb-20">

              <div className="col-lg-7 col-md-12 mt-3 contactus" id='CON'>
                <h1>Let's connect and discuss tailored solutions for digital lending!</h1>
                <hr />
                <p><strong>Address</strong> : 303, 3rd Floor, Gopal Heights, Netaji Subhash Place, Pitampura, Delhi-110034</p>
                <p><strong>Email Id</strong> : care@superline.in</p>
                <p><strong>Mobile Number</strong>: 011-66668018</p>
              </div>
              <div className="col-lg-5 col-md-12 mt-3">
                <h3>Or you can also contact us here</h3>
                <br />
                <div class="input-group mb-3">
                  {/* <span class="input-group-text" id="basic-addon1"></span> */}
                  <input type="text" class="form-control" placeholder="Name" aria-label="Username" aria-describedby="basic-addon1" />
                </div>

                <div class="input-group mb-3">
                  <input type="email" class="form-control" placeholder="Email" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                  {/* <span class="input-group-text" id="basic-addon2">@example.com</span> */}
                </div>

                <div class="mb-3">
                  {/* <label for="basic-url" class="form-label">Your vanity URL</label> */}
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon3">+91</span>
                    <input type="number" class="form-control" id="basic-url" aria-describedby="basic-addon3 basic-addon4" />
                  </div>
                </div>

                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Address" aria-label="Amount (to the nearest dollar)" />
                </div>

                <div class="input-group">
                  {/* <span class="input-group-text">With textarea</span> */}
                  <textarea class="form-control" placeholder="Message" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <br />

                <div class="d-grid gap-2">

                  <button class="btn btn-primary" type="submit">Send </button>
                </div>


              </div>
            </div>
          </div>
        </div>




      </div>
    </section>
  )
}

export default GetStarted