import Certificate from '../assets/nbfcCertificate.png'

import CustomNavbar2 from '../components/Navbar/Navbar2';
import Footer2 from "../components/Footer/Footer2";
function NbfcCertificate() {
    return (
        <>
            <CustomNavbar2 />
            <div className="container" style={{ paddingTop: '120px' }}>
                <div className="column">
                    <div className="tex ptb-70" style={{ textAlign: 'center' }}>
                        <h1 className='mb-4'>NBFC Certificate</h1>
                        <img src={Certificate} alt="" style={{ width: '50%' }} />
                    </div>

                </div>

            </div>
            <Footer2 />
        </>
    )
}

export default NbfcCertificate