import BannerImg from '../../assets/banner-img1.png'
import BannerImg2 from '../../assets/banner-img2.png'
function Banner(){
    return(
        <div className="banner-wrapper" id="banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8">
                        <div className="banner-wrapper-content">
                            <h1>Superline revolutionizes <span>Digital Lending</span>, merging groundbreaking innovation with accessible <span>Financial Empowerment</span></h1>
                            {/* <a href="https://play.google.com/store/apps/details?id=com.borrowBuddy" target="_blank" className="btn btn-primary ">Apply Now</a> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="banner-wrapper-image text-center mt-3">
                            <img src={BannerImg2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
