import { Route, Routes } from 'react-router-dom';
import './App.css'


import Homepage from './pages/Homepage';
import Certificate from './pages/Nbfc'

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route index element={<Homepage />} />
      
        <Route path='/nbfcCeritificate' element={<Certificate />} />
      </Routes>
     
    </div>
  );
}

export default App;
