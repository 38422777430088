import { Link , useNavigate } from "react-router-dom";
import LogoBlack from '../../assets/superline/logowhite.png'
import MapImg from '../../assets/map.png'
import { companyFullname } from "../../Variables";
import Phone from '../../assets/phone.png'
import Mail from '../../assets/mail.png'
import Pin from '../../assets/pin.png'

function Footer2(){
    let navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // const handleLinkClick1 = (sectionId) => {

    //     navigate('/');


    //     setTimeout(() => {

    //         window.scrollToSection(sectionId);
    //     }, 200);
    // };
    const handleLinkClick1 = (sectionId) => {
        navigate('/');  
    
       
        setTimeout(() => {
            window.scrollToSection(sectionId); 
            // console.log(sectionId);
            // window.scrollToSection = (sectionId) => {
            //     const sectionElement = document.getElementById(sectionId);
            //     console.log(sectionElement);
            //     if (sectionElement) {
            //         sectionElement.scrollIntoView({
            //             behavior: 'smooth',
            //         });
            //     }
            // }; 
        },700);
    };
    
    return(
        <footer className="footer-area">
            <div className="container">
                
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to={{
                                        pathname: "/",
                                    }} onClick={scrollToTop}>
                                    <img src={LogoBlack} width={"40%"} alt="Logo black" />
                                </Link>
                            </div>
                            <br />
                            {/* <ul className="footer-contact-info">
                                <li>
                                    <h4>Contact Us</h4>
                                    <span>Address:</span>
                                    {companyFullname}
                                    <br />
                                     303, 3rd Floor, 
                                    Gopal Heights, Netaji Subhash Place, 
                                    Pitampura, Delhi-110034
                                </li>
                                <li>
                                    <span>Email:</span>
                                    <a href="mailto:info@superline.in">info@superline.in</a>
                                </li>
                                <li>
                                    <span>Phone:</span>
                                    <a href="tel:011- 42451148">011- 42451148</a>
                                </li>
                                <li>
                                    <span>CIN:</span>
                                    <a href="#">U74899DL1994PTC060605</a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-footer-widget">
                            <h3>Contact Us</h3>
                            {/* <ul className="list"> */}
                            <div className="row row-cols-2">

                            <img src={Phone} alt="" />  
                                <a><Link to={""}>011-66668018</Link></a>
                            </div>
                            <br />
                            <div className="row row-cols-2">
                                <img src={Mail} alt="" />  
                                <a><Link to={""}>care@superline.in</Link></a>

                            </div>
                                <div className="row row-cols-2 mt-4" >
                                <img src={Pin} alt="" />  
                                <a><Link to={""}>303, 3rd Floor, Gopal Heights Netaji Subhash Place, Pitampura,Delhi-110034 </Link></a>

                                </div>
                            {/* </ul> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-footer-widget">
                            <h3>Quick Links</h3>
                            <ul className="list">
                                <li><Link to="/" onClick={() => handleLinkClick1('AU')}>About Us</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('PR')}>Product</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('CON')}>Contact Us</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('SB')}>Sourcing Partner (Borrowbuddy)</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('FAQ')}>FAQs</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="single-footer-widget">
                            <h3>Compliances</h3>
                            <ul className="list">
                                <li><Link to="/" onClick={() => handleLinkClick1('CP')}>Privacy policy</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('CP')}>Interest Rate Policy</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('CP')}>KYC AML Policy</Link></li>
                                <li><Link to={{pathname:"/nbfcCeritificate"}} target="_blank"  onClick={scrollToTop}>NBFC Certificate</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('CP')}>Fair practice Code</Link></li>
                                <li><Link to="/" onClick={() => handleLinkClick1('CP')}>Grievance Redressal</Link></li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
                <div className="copyright-area">
                <span>© Copyright 2023 Superline Fininvest Pvt. Ltd   | All Rights Reserved</span>
                </div>
            </div>
            {/* <br /> */}
            {/* <div className="map-image">
                <img src={MapImg} alt="" />
            </div> */}

            
        </footer>
    )
}

export default Footer2