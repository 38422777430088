import { BsGraphUpArrow } from 'react-icons/bs'
import { FaAudible } from 'react-icons/fa'
import { companyFullname, companyName, incorporatedYear } from '../../Variables';


function Welcome(){
    return(
        <section className="about-us-area bg-f4fcff mt-5 mb-5" id='AU'>
            <div className="container">
                
                    <div className="col-lg-12 col-md-12">
                        <div className="about-us-content">
                            <h1>About Us</h1>
                            <hr />
                            <p>
                            Superline Fininvest Pvt. Ltd., with registration number B-14.01967, is a non-banking finance company that has been approved by the Reserve Bank of India. Superline is a forward-thinking financial institution that was founded on August 1, 1994, under the Companies Act of 1956. Its primary focus is providing short-term unsecured personal loans via its modern technological platform, Borrowbuddy, which is India's fastest loan-giving app tailored for young salaried professionals.</p>
                            <p>Superline understands the essential needs of modern professionals ,which include easy and rapid lending access, conditions devoid of collateral, and a trustworthy financial partner. Our goal is to close the current gap in the market by offering creative and adaptable credit solutions designed specifically for young professionals. Because these solutions are provided via a digital platform, efficiency and a user-friendly experience are guaranteed.</p>
                            <p>Name of the Key Holders :<strong> Abhinav Singhal</strong>
                                  </p>

                            <div className="mission  ptb-70">
                            <h1>Mission</h1>
                            <hr />
                            <p>
                            At Superline, our mission is straightforward: to make borrowing easy, transparent, and accessible to everyone in the digital era. We're committed to leveraging technology for a hassle-free lending experience. Whether it's a short-term need or a financial goal, we aim to empower individuals with swift and tailored solutions. Our focus is on simplicity, convenience, and customer-centricity, setting a new standard in digital lending for a brighter financial future.</p>

                            </div>

                            
                        </div>
                    </div>
                </div>
        </section>
    );
}

export default Welcome