import './navbar.css'
import logo from '../../assets/logo.png'
import { BiChevronDown } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'

import { useState } from 'react';
// import { NavLink } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

function CustomNavbar(){
    const [opened, setOpened] = useState(false);
  
    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    // const Close = () => setClick(false);

    window.scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };
    function scrollToSection(sectionId) {
        setOpened(false);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    return (
        <div className="navbar-area">
        <div className="container d-flex flex-row justify-content-between align-items-center">

            <div className="navbar-brand">
                <Link to={{ pathname: "/" }} onClick={scrollToTop}>
                    <img className='navbar-brand-img' alt="" />
                </Link>
            </div>
            <div className="navItems">
                <li ><NavLink href='#' onClick={() => scrollToSection('banner')} >Home</NavLink></li>
                <li><NavLink href='#' onClick={() => scrollToSection('AU')}>About Us</NavLink></li>
                    
                {/* <li className="" type="button" data-bs-toggle="dropdown" aria-expanded="false" id='dropdownMenu'>
                    Services <BiChevronDown />
                </li>
                    <ul className="dropdown-menu">
                        <li><Link to={"/direct-business-lending"} className='dropdown-item'>Direct Business Lending</Link></li>
                        <li><Link to={"/digital_lending"} className='dropdown-item'>Digital Lending</Link></li>
                    </ul> */}
                <li><NavLink href='#' onClick={() => scrollToSection('PR')}>Product</NavLink></li>
                <li><NavLink href='#' onClick={() => scrollToSection('CP')}>Compliances</NavLink></li>
                    
                <li><NavLink href='#' onClick={() => scrollToSection('FAQ')}>FAQs</NavLink></li>
                <li><NavLink href='#' onClick={() => scrollToSection('CON')}>Contact Us</NavLink></li>
                {/* <button type="button" class="btn btn-outline-warning">Log In</button> */}
            </div>
            <div className="nav-toggler-icon" onClick={handleOpen}>
                <GiHamburgerMenu className='hamIcon' />
            </div>
            <div id='customSidebar' className={opened?"custom-sidebar show":"custom-sidebar"}>
                <div className="close-btn-wrapper">
                    <AiOutlineClose className='close-icon' onClick={handleClose} />
                </div>
                <ul className='sidebar-list'>
                    <li><NavLink  href='#' onClick={() => scrollToSection('banner')} >Home</NavLink></li>
                    <li><NavLink href='#' onClick={() => scrollToSection('AU')}>About Us</NavLink></li>
                    
                    <li><NavLink href='#' onClick={() => scrollToSection('PR')}>Product</NavLink></li>
                <li><NavLink href='#' onClick={() => scrollToSection('CP')}>Compliances</NavLink></li>
                    
                <li><NavLink href='#' onClick={() => scrollToSection('FAQ')}>FAQs</NavLink></li>
                <li><NavLink href='#' onClick={() => scrollToSection('CON')}>Contact Us</NavLink></li>
                {/* <button type="button" class="btn btn-outline-warning" style={{marginLeft:'32px'}}>Log In</button> */}
                </ul>
            </div>
        </div>
        </div>
    );
  }

export default CustomNavbar