import About from "../components/About/About";
import Banner from "../components/Banner";
import CustomNavbar from '../components/Navbar/index';
import Footer from "../components/Footer/Footer";
import GetStarted from "../components/GetStarted/GetStarted";
import Services from "../components/Services/Services";
import Welcome from "../components/Welcome";

import { useEffect } from "react";

function Homepage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <CustomNavbar />
            <Banner />
            <Welcome />
            <Services />
            <About />
            <GetStarted />
            <Footer />
        </>
    )
}

export default Homepage