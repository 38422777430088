import { FaBullseye, FaEye } from 'react-icons/fa'
import PaymentImg from '../../assets/payment-features1.jpg'
import PatmentImg2 from '../../assets/payment-features2.jpg'
import Arrow from '../../assets/arrow.png'
import Application1 from '../../assets/application1.png'
import Application2 from '../../assets/application2.png'
import Application3 from '../../assets/application3.png'
import Application4 from '../../assets/application4.png'
import Application5 from '../../assets/application5.png'
import ArrowRight from '../../assets/arrow-right.png'
import ArrowLeft from '../../assets/arrow-left.png'
import BorrowBuddyLogo from '../../assets/borrowBuddy.png'
import Arrow2 from '../../assets/arrow2.png'
import React, { useState } from 'react';
// import { Carousel } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import { companyFullname, companyName, incorporatedYear } from '../../Variables';

function About() {
    function dropdownHandler() {
        let elem = document.getElementsByClassName('nice-select')[0];
        if (elem) elem.classList.toggle("open");
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    const handlePrev = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? 4 : prevIndex - 1));
    };

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex === 4 ? 0 : prevIndex + 1));
    };

    return (
        <section className="compliances-area ptb-70" id='CP'>
            <div className="container">
                <div className="row ">

                    <div className="col-lg-4 col-md-12">
                        <div className="compliances-area1">
                            <h1>Compliances</h1>
                            <hr />
                        </div>

                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="row compliances-area2">

                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>Fair Practice Code</h4>
                                    <a href="" className='mt-3'>View More <img src={Arrow} alt="" /></a>
                                </div>
                            </div>


                            <div className="col-lg-4  col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>Grievance Redressal</h4>
                                    <a href="" className='mt-3'>View More <img src={Arrow} alt="" /></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>Privacy Policy</h4>
                                    <a href="" className='mt-3'>View More <img src={Arrow} alt="" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>Interest Rate Policy</h4>
                                    <a href="" className='mt-3'>View More <img src={Arrow} alt="" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>KYC & AML Policy</h4>
                                    <a href="" className='mt-3'>View More <img src={Arrow} alt="" /></a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                <div className="card mt-3">
                                    <h4>NBFC Certificate</h4>
                                    <a className='mt-3'><Link to={"/nbfcCeritificate"} target="_blank">View More <img src={Arrow} alt="" /></Link></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="compliances-area1 ptb-70 ">
                    <h1>Application Process</h1>
                    <hr />
                    {/* <section className="pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-12">
                <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                    <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">

                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">

                                    <div className="col-md-4 mb-3">
                                        <div className="card">
                                        <Carousel.Item>
                                            <img className="img-fluid" src={Application1}  alt="First slide"/>
                                            <div className="card-body">
                                            <Carousel.Caption>
                                                <h4 className="card-title">Initiation</h4>
                                                <p className="card-text">Click on the Apply Now Button to Open the Online Application Form</p>
                                                 </Carousel.Caption>

                                            </div>
                                            </Carousel.Item>

                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                    <div className="card">
                                    <Carousel.Item>
                                            <img className="img-fluid"  src={Application2}  alt="Second slide"/>
                                            <div className="card-body">
                                            <Carousel.Caption>
                                                <h4 className="card-title">Application</h4>
                                                <p className="card-text">Submit Loan Application with Necessary Documents</p>
                                                </Carousel.Caption>


                                            </div>
                                            </Carousel.Item>

                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                    <div className="card">
                                    <Carousel.Item>
                                            <img className="img-fluid" src={Application3}  alt="Third slide"/>
                                            <div className="card-body">
                                            <Carousel.Caption>
                                                <h4 className="card-title">Approval</h4>
                                                <p className="card-text">Get Instant In-Principal Approval Based on Details</p>
                                                </Carousel.Caption>

                                            </div>
                                            </Carousel.Item>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">

                                    <div className="col-md-4 mb-3">
                                    <div className="card">
                                    <Carousel.Item>
                                            <img className="img-fluid"  src={Application4}  alt="Fourth slide"/>
                                            <div className="card-body">
                                            <Carousel.Caption>
                                                <h4 className="card-title">Verification</h4>
                                                <p className="card-text"> Complete Video KYC and E-sign Process</p>
                                                </Carousel.Caption>

                                            </div>
                                            </Carousel.Item>

                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                    <div className="card">
                                    <Carousel.Item>
                                            <img className="img-fluid" src={Application5}  alt="Fifth slide"/>
                                            <div className="card-body">
                                            <Carousel.Caption>
                                                <h4 className="card-title">Disbursement</h4>
                                                <p className="card-text">Successful Loan Disbursement in Your Bank Account</p>
                                                </Carousel.Caption>
                                            </div>
                                            </Carousel.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Carousel>


                </div>
                <div className="col-6 text-right">
                    <a className="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev"  onClick={()=>handlePrev()}>
                        <img src={ArrowLeft} alt="" />
                        <i className="fa fa-arrow-left"></i>
                    </a>
                    <a className="btn btn-primary mb-3 " href="#carouselExampleIndicators2" role="button" data-slide="next"  onClick={()=>handleNext()}>
                    <img src={ArrowRight} alt="" />
                        <i className="fa fa-arrow-right"></i>
                    </a>
                </div>
                <div className="col-6 text-right">
                <button className="btn btn-primary mb-3 mr-1" onClick={handlePrev}>
                  <img src={ArrowLeft} alt="" />
                </button>
                <button className="btn btn-primary mb-3 " onClick={handleNext}>
                  <img src={ArrowRight} alt="" />
                </button>
              </div>
            </div>
        </div>
                        </section>                      */}

                    <OwlCarousel className='owl-theme' loop dots={false} responsiveClass={true} responsive={
                        {
                            0: {
                                items: 1,
                                dots: false,
                                nav: true,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,

                            },
                            600: {
                                items: 2,
                                dots: false,
                                nav: true,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,
                            },
                            1000: {
                                items: 3,
                                dots: false,
                                nav: true,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,
                               
                            }
                        }
                    }>

                        <Card imagess={Application1} link='#' clr1={"#4154F1"} clr2={"#011CFF"} title={"Initiation"} content={"Click on the Apply Now Button to Open the Online Application Form"} />
                        <Card imagess={Application2} link='#' clr1={"#63BC6F"} clr2={"#A0F8AC"} title={"Application"} content={"Submit Loan Application with Necessary Documents"} />
                        <Card imagess={Application3} link='#' clr1={"#4154F1"} clr2={"#6C7CFF"} title={"Approval"} content={"Get Instant In-Principal Approval Based on Details"} />
                        <Card imagess={Application4} link='#' clr1={"#F84E77"} clr2={"#FFA3B9"} title={"Verification"} content={" Complete Video KYC and E-sign Process"} />
                        <Card imagess={Application5} link='#' clr1={"#FC573C"} clr2={"#FB9281"} title={"Disbursement"} content={"Successful Loan Disbursement in Your Bank Account"} />
                    </OwlCarousel>

                </div>

                <div className="sourcing-partner ptb-40" id="SB">
                    <h1>Our Sourcing Partner</h1>
                    <hr />
                    <div className="container">
                        <div className="row align-items-center partnersrow">
                            <div className="col-lg-4 col-md-12 text-center">
                                <img src={BorrowBuddyLogo} alt="" />
                            </div>

                            <div className="col-lg-8 col-md-12">
                                <p>Transforming dreams into reality with BorrowBuddy's seamless digital lending experience.</p>
                                {/* <button className="btn btn-primary" type="submit">BorrowBuddy  <img id='first' className='arrow' src={Arrow2} alt="" /></button> */}
                                <a href="https://borrowbuddy.co.in/" target="_blank" className="btn btn-primary">BorrowBuddy <img id='first' className='arrow' src={Arrow2} alt="" /></a>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default About

function Card({ imagess, link = "#", title, content, clr1 = "#63BC6F", clr2 = "#A0F8AC" }) {

    return (
        <div className="item card mb-5">
            {/* <p>rsdtfyguhjk</p> */}
            {/* <img className='card-curve-lines' src="" alt="" /> */}
            {/* <div className="icon" >
                    
                    
                </div> */}
            <div className="info">
                <img src={imagess} alt="" style={{ width: '20%' }} />
                <br />
                <h4 className='card-title'><a href={link}>{title}</a></h4>
                <p className='card-text'>{content}</p>
                {/* <a className='card-link' href={link}>Read More</a> */}

            </div>

        </div>

    )
}