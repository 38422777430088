import './navbar.css'
import logo from '../../assets/logo.png'
import { BiChevronDown } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineClose } from 'react-icons/ai'

import { useNavigate } from "react-router-dom";
import { useState } from 'react';
// import { NavLink } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';

function CustomNavbar2(){
    let navigate = useNavigate();
    const [opened, setOpened] = useState(false);
  
    const handleOpen = () => setOpened(true);
    const handleClose = () => setOpened(false);
    // const Close = () => setClick(false);

    const handleLinkClick = (sectionId) => {
        setOpened(false);

        setTimeout(()=>{
            navigate('/');
        },100);

        setTimeout(() => {

            window.scrollToSection(sectionId);
        }, 500);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className="navbar-area">
        <div className="container d-flex flex-row justify-content-between align-items-center">

            <div className="navbar-brand">
                <Link to={{ pathname: "/" }} onClick={scrollToTop}>
                    <img className='navbar-brand-img' alt="" />
                </Link>
            </div>
            <div className="navItems">
                <li ><NavLink to="/" onClick={()=>handleLinkClick('banner')}  >Home</NavLink></li>
                <li><NavLink to="/" onClick={() => handleLinkClick('AU')}>About Us</NavLink></li>
                    
                {/* <li className="" type="button" data-bs-toggle="dropdown" aria-expanded="false" id='dropdownMenu'>
                    Services <BiChevronDown />
                </li>
                    <ul className="dropdown-menu">
                        <li><Link to={"/direct-business-lending"} className='dropdown-item'>Direct Business Lending</Link></li>
                        <li><Link to={"/digital_lending"} className='dropdown-item'>Digital Lending</Link></li>
                    </ul> */}
                <li><NavLink to="/" onClick={() => handleLinkClick('PR')}>Product</NavLink></li>
                <li><NavLink to="/" onClick={() => handleLinkClick('CP')}>Compliances</NavLink></li>
                    
                <li><NavLink to="/" onClick={() => handleLinkClick('FAQ')}>FAQs</NavLink></li>
                <li><NavLink to="/" onClick={() => handleLinkClick('CON')}>Contact Us</NavLink></li>
                {/* <button type="button" class="btn btn-outline-warning">Log In</button> */}
            </div>
            <div className="nav-toggler-icon" onClick={handleOpen}>
                <GiHamburgerMenu className='hamIcon' />
            </div>
            <div id='customSidebar' className={opened?"custom-sidebar show":"custom-sidebar"}>
                <div className="close-btn-wrapper">
                    <AiOutlineClose className='close-icon' onClick={handleClose} />
                </div>
                <ul className='sidebar-list'>
                    <li><NavLink to="/" onClick={()=>handleLinkClick('banner')}>Home</NavLink></li>
                    <li><NavLink to="/" onClick={() => handleLinkClick('AU')}>About Us</NavLink></li>
                    
                    <li><NavLink to="/" onClick={() => handleLinkClick('PR')}>Product</NavLink></li>
                <li><NavLink to="/" onClick={() => handleLinkClick('CP')}>Compliances</NavLink></li>
                    
                <li><NavLink to="/" onClick={() => handleLinkClick('FAQ')}>FAQs</NavLink></li>
                <li><NavLink to="/" onClick={() => handleLinkClick('CON')}>Contact Us</NavLink></li>
                {/* <button type="button" class="btn btn-outline-warning" style={{marginLeft:'32px'}}>Log In</button> */}
                </ul>
            </div>
        </div>
        </div>
    );
  }

export default CustomNavbar2