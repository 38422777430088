import { Link } from 'react-router-dom'
import BussinessImg from '../../assets/business-img.jpg'
import ProductImg from '../../assets/product-image.png'
import Icon1 from '../../assets/icon1.png'
import Icon2 from '../../assets/icon2.png'
import Icon30 from '../../assets/icon30.png'
import Icon4 from '../../assets/icon4.png'
import Icon5 from '../../assets/icon5.png'
import Icon6 from '../../assets/icon6.png'
import DirectMessage from '../../assets/directMessage.png'
import Icon3 from '../../assets/product-image-four.png'
import { companyName } from '../../Variables';

function Services() {
    return (
        <section className="product-area" id='PR'>         <div className="container">
            <div className="col">
                <div className="row align-items-center">

                    <div className="col-lg-6 col-md-12">
                        <div className="product-content">
                            <h1>Product</h1>
                            <h5>Digital Lending Solution</h5>
                            <hr />
                            <p>Upgrade your financial journey with our advanced digital lending solutions. Our efficient processes, enhanced security, and personalized service set a new standard in lending. Explore a smarter way to borrow with us.</p>
                            <a href="https://play.google.com/store/apps/details?id=com.borrowBuddy" target="_blank" className="btn btn-primary mt-3">Apply Now</a>
                        
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="product1-image text-center mt-3">
                            <img src={BussinessImg} alt="" />
                        </div>
                    </div>
                </div>


                <div className="product-two-content">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-12">
                            <div className="productt mt-4">
                                <p>Empowered by dynamic features! 🔥</p>
                                <h1>Empowering Finance: Explore <span>Digital Lending</span> Features.</h1>
                                <div className="product-image">
                                    <img src={ProductImg} width={'80%'} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="product-three-content mt-4">
                                {/* <div className="row row-cols-2"> */}
                                <div className="row">
                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine">
                                            <img src={Icon1} alt="" />
                                            <h4>Accessible Anytime, Anywhere</h4>
                                            <p>Whether through our mobile app, access BorrowBuddy at your fingertips whenever you need it.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine">
                                            <img src={Icon2} alt="" />
                                            <h4>Swift Application Process</h4>
                                            <p>Experience a super-convenient and hassle-free application process that can be completed within 10 minutes.</p>
                                        </div>
                                    </div>


                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine">
                                            <img src={Icon30} alt="" />
                                            <h4> Instant Approvals</h4>
                                            <p>Completed applications are approved instantly, ensuring a quick response to your financial needs.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine">
                                            <img src={Icon4} alt="" />
                                            <h4>Direct Money Transfer</h4>
                                            <p>Once approved, the money is transferred directly to your bank account, providing swift access to the funds you require.</p>
                                        </div>
                                    </div>



                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine ">
                                            <img src={Icon5} alt="" />
                                            <h4> Transparent Terms</h4>
                                            <p>No surprises. Clear terms and conditions for a worry-free borrowing experience.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-xs-12 mt-4">
                                        <div class="verticalLine">
                                            <img src={Icon6} alt="" />
                                            <h4>Minimal Documentation</h4>
                                            <p>Minimal documentation for maximum efficiency..</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="product-four-content ptb-70">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 mt-4" id="contentColumn">

                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />
                                <p> Uploaded documents must be clear and correspond with the information provided in the app.</p>
                            </div>
                            <br />
                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />
                                <p>Loans exclusively offered to employed individuals.</p>
                            </div>
                            <br />
                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />
                                <p> Minimum net take-home salary requirement: Rs 15,000.</p>
                            </div>
                            <br />
                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />
                                <p>Bank statements must demonstrate monthly salary credits.</p>
                            </div>
                            <br />
                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />

                                <p> <strong> Loan Amount: </strong><span className='text-secondary'>Loan can be taken in the range of </span> <strong> Rs 5,000 - Rs 2,00,000</strong>. </p>
                            </div>
                            <br />
                            <div class="verticalLine" >
                                <img src={DirectMessage} width={70} alt="" />

                                <p><strong> Loan Tenure: </strong><span className='text-secondary'>  Loan tenure is upto 12 Months with flexible repayment option.</span>  </p>
                            </div>


                        </div>
                        <div className="col-lg-4 col-md-12 mt-4" id="imageColumn">
                            <div className='me-3' style={{ float: 'right' }}>
                                <h1 className='text-end'>Loan Eligibility</h1>
                                <hr />
                            </div>
                            <div className="product-image4">
                                <img src={Icon3} width={'80%'} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
        </section>
    )
}

export default Services

function Service({ heading, content, to }) {
    return (
        <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="single-business-box">
                <h3>{heading}</h3>
                <p className='text-black'>{content}</p>
                {/* <a href="direct-business-lending.html" >Read More</a> */}
                <Link to={to} className="btn btn-primary">Read More</Link>
            </div>
        </div>

    )
}